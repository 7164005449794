define('gusto/js/app/gusto.ticketmaster',['jquery', 'jquery-ui-autocomplete', '!domready'], function($) {
    'use strict';

    $.widget("custom.catcomplete", $.ui.autocomplete, {
        _create: function() {
            this._super();
            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
        },
        _renderMenu: function(ul, items) {
            var that = this,
                currentCategory = "";
            $.each(items, function(index, item) {
                var li;
                if (item.group !== currentCategory) {
                    ul.append("<li class='ui-autocomplete-category'>" + item.group + "</li>");
                    currentCategory = item.group;
                }
                li = that._renderItemData(ul, item);
                if (item.category) {
                    li.attr("aria-label", item.group + " : " + item.value);
                }
            });
        },
        _renderItem: function(ul, item) {
            return $("<li>").html(item.value).appendTo(ul);
        },
        _resizeMenu: function() {
            var ul = this.menu.element;
            ul.outerWidth(this.element.outerWidth());
        }
    });

    $("#js_tm-search__input").catcomplete({
        delay: 0,
        source: function(request, response) {
            // we trust that the variable 'data' is in global scope? feels fragile!
            var filteredArray = $.map(window.data, function(item) {
                if (item.value.toLowerCase().indexOf(request.term.toLowerCase()) === 0) {
                    return item;
                } else {
                    return null;
                }
            });
            response(filteredArray);
        },
        select: function(event, ui) {
            if (ui.item) {
                $('#js_tm-search__input').val(ui.item.value);
            }
            $('.tm-search__form').submit();
        }
    });
    //.on('keydown', function() {
    //    $('#ticketmaster_search_reset').show();
    //});

    // figure out which tab out search is in, and tweak the form value accordingly...
    $('div.facet').each(function(index, element) {
        var $tm = $(element).find('.tm-search__form');
        if ($tm) {
            $tm.find('.tm-search__initial_tab').val(index);
        }
    });

    //// Clear button is broken by the iframe so it is disabled
    //$('#ticketmaster_search_reset').on('touchstart click', function() {
    //    $('#ticketsearch').val('');
    //    $(this).hide();
    //    return false;
    //});
    //
    //if ($('#ticketsearch').val()) {
    //    $('#ticketmaster_search_reset').show();
    //}
});

